var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"programs-grade"},[_c('div',{staticClass:"programs-grade--title"},[_c('TextAtom',{attrs:{"value":_vm.getGradeLabelName,"tag":"label","font":"poppins","color":"neutral-800","weight":"400","size":"subtitle"}}),_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#646464"}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"clickable",on:{"click":function($event){return _vm.deleteGrade()}}},[_c('v-list-item-title',{staticStyle:{"color":"#CCCCCC"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.profile.delete'))+" ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"#CCCCCC"}},[_vm._v(" mdi-delete ")])],1)],1)],1)],1),_c('div',[_c('div',{staticClass:"flex-info flex-row mt-7 gap-7"},[_c('EditableGroup',{staticClass:"flex-1",attrs:{"fields":[
          {
            type: 'dropdown',
            varName: 'grade_id',
            dense: true,
            input: _vm.getGradeId(),
            items: [..._vm.getGradeOptions],
            openModal: true,
            padding: '0px',
            dropdownColor: '#F5F5F5',
            place: _vm.$t('dashboard.digital_profile.programs.grade_list.title'),
          },
        ]},on:{"setObject":function($event){return _vm.setGrade(...arguments)}}}),_c('EditableGroup',{staticClass:"flex-1",attrs:{"fields":[
          {
            type: 'text',
            varName: 'personalized_name',
            dense: true,
            input: _vm.getPersonalizedName(),
            textColor: '#F5F5F5',
            title: _vm.$t('dashboard.digital_profile.programs.grade_list.grade_name'),
            titleText: false,
            backgroundColor: '#F5F5F5',
          },
        ]},on:{"setObject":function($event){return _vm.setPersonalizedName(...arguments)}}})],1),_c('TextAtom',{staticClass:"d-flex mt-12 mb-5",attrs:{"value":_vm.$t('dashboard.digital_profile.programs.title'),"tag":"label","font":"poppins","color":"primary-dark","weight":"400","size":"subtitle"}}),_c('div',[_vm._l((_vm.getCampusGrade),function(grade,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(_vm.year)+" - "+_vm._s(grade)+" "),(grade.year === _vm.year)?_c('ProgramSingle',{staticClass:"mt-5",attrs:{"grade":grade},on:{"deleteProgram":function($event){return _vm.deleteProgram(index, ...arguments)},"setGenderSelected":function($event){return _vm.setGender(index, ...arguments)},"setModality":function($event){return _vm.setModality(index, ...arguments)},"setSpecialty":function($event){return _vm.setSpecialty(index, ...arguments)},"setShift":function($event){return _vm.setShift(index, ...arguments)}}}):_vm._e()],1)}),_c('div',{staticClass:"d-flex left-text clickable mt-5",on:{"click":_vm.add,"keydown":_vm.add}},[_c('SVGIcon',{attrs:{"icon":"more-border-gray.svg","size":"17"}}),_c('TextAtom',{staticClass:"ml-2 clickable",attrs:{"value":_vm.$t('dashboard.digital_profile.programs.grade_list.add_program'),"tag":"label","font":"inter","color":"neutral-800","weight":"400","size":"normal"}})],1)],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }